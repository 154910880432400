<template>
  <div class="container cont">
    <img src="/imagenes/logo_t.png" alt="Descripción de la imagen" class="logo">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"> <router-link :to="'/socio/' + rutina.user_id">Volver</router-link></li>
      </ol>
    </nav>
    <h1>Rutina de entrenamiento </h1>


    <form v-on:submit.prevent = "editarRutina">
      <div class="design">
        <div class="row">

          <div class="col-12" style="margin-bottom: 20px">
            <!-- Name input -->
            <div class="form-outline">
              <label class="form-label">Objetivos</label>
              <input type="text" class="form-control" v-model="getRutina.objetivos">
            </div>
          </div>

          <div class="col-12" style="margin-bottom: 20px">
            <!-- Name input -->
            <div class="form-outline">
              <label class="form-label">Observaciones</label>
              <input type="text" class="form-control" v-model="getRutina.observaciones">
            </div>
          </div>

        </div>

        <div v-for="(detalle, index) in rutina.detalles" :key="index">
          <div class="row">
            <div class="col">
              <div class="form-outline">
                <label class="form-label">Día Número</label>
                <select class="form-select" v-model="detalle.dia" :required="index === 0">
                  <option disabled value="">Seleccionar día</option>
                  <option v-for="i in 5" :key="i" :value="i">{{ i }}</option>
                </select>
              </div>
            </div>

            <div class="col">
              <div class="form-outline">
                <label class="form-label">Ejercicio</label>
                <select class="form-select" :required="index === 0" v-model="detalle.ejercicio.id">
                  <option disabled value="">Seleccionar Ejercicio</option>
                  <option v-for="ejercicio in ejercicios" :value="ejercicio.id" :key="ejercicio.id">{{ ejercicio.nombre }}</option>
                </select>
              </div>
            </div>

            <div class="col">
              <div class="form-outline">
                <label class="form-label">Cant. Series</label>
                <select class="form-select" :required="index === 0" v-model="detalle.series">
                  <option disabled value="">Seleccionar cantidad de series</option>
                  <option v-for="i in 8" :key="i" :value="i">{{ i }}</option>
                </select>
              </div>
            </div>


            <div class="col">
              <div class="form-outline">
                <label class="form-label">Cant. Repeticiones</label>
                <input type="number" class="form-control" v-model="detalle.cantidad">
              </div>
            </div>
          </div>
          <hr />
        </div>












        <div>
          <div v-for="(row, index) in filas_restantes" :key="index">
            <div class="row">
              <div class="col">
                <div class="form-outline">
                  <label class="form-label">Día Número</label>
                  <select class="form-select" v-model="dia[index]" >
                    <option disabled value="">Seleccionar día</option>
                    <option v-for="i in 5" :key="i" :value="i">{{ i }}</option>
                  </select>
                </div>
              </div>

              <div class="col">
                <div class="form-outline">
                  <label class="form-label">Ejercicio</label>
                  <select class="form-select"  v-model="ejercicio_id[index]">
                    <option disabled value="">Seleccionar Ejercicio</option>
                    <option v-for="ejercicio in ejercicios" :value="ejercicio.id" :key="ejercicio.id">{{ ejercicio.nombre }}</option>
                  </select>
                </div>
              </div>

              <div class="col">
                <div class="form-outline">
                  <label class="form-label">Cant. Series</label>
                  <select class="form-select"  v-model="series[index]">
                    <option disabled value="">Seleccionar cantidad de series</option>
                    <option v-for="i in 8" :key="i" :value="i">{{ i }}</option>
                  </select>
                </div>
              </div>

              <div class="col">
                <div class="form-outline">
                  <label class="form-label">Cant. Repeticiones</label>
                  <input type="number" class="form-control" v-model="cantidad[index]">
                </div>
              </div>
            </div>
            <hr v-if="index !== filas_restantes - 1" />
          </div>



          <div class="row">
            <div class="col">
              <input type="submit" class="fadeIn fourth" value="Guardar Rutina">
            </div>
          </div>
        </div>




      </div>

    </form>
  </div>
</template>

<script>
// import {Global} from '@/Global'

import axios from "axios";
import {Global} from "@/Global";

const Swal = require('sweetalert2')

  export default {
    name:'RutinaEditarView',
    props: ['rutina'],
    mounted(){
      if (localStorage.getItem('token')){
        this.getEjercicios();

      }
    },
    methods: {
      getEjercicios(){
        let config = {
          headers: {
            // 'Authorization': 'Bearer ' + Global.token
            'Authorization': 'Bearer ' + localStorage.getItem('token')
          },
        }
        axios.get(Global.url('ejercicios'), config)

            .then(res=>{
              // console.log(res.data.data)
              if(res.status == 200){
                this.ejercicios=res.data.data;
                // console.log(this.ejercicios);
              }

            }).catch(error => {
          // console.log(error.response.data.message)
          this.error = true;
          this.error_msg = error.response.data.res;
        });
      },
        editarRutina(){

            const config = {
                headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
            };

            const bodyParameters = {
                id : this.rutina.id,
                objetivos : this.rutina.objetivos,
                observaciones : this.rutina.observaciones,
                dia : this.dia,
                ejercicio_id : this.ejercicio_id,
                series : this.series,
                cantidad : this.cantidad,
                rutina: this.rutina,
                
            };

            axios.put( 
            'http://apiquality.webssdigital.com.ar/api/rutinas/'+this.rutina.id,
            bodyParameters,
            config
            ).then(res=>{
                    console.log(res.data)
                if(res.status == 202){
                   
            
                         Swal.fire({
                        title: 'Rutinas',
                        text: 'Rutina editada correctamente',
                        icon: 'success',
                        confirmButtonText: 'Cool'
                        }).then(() => this.$router.push({ name: 'dashboard' }))
                    
                }
                    
            })
            .catch(error => {
            console.log(error)
            // this.error = true;
            // this.error_msg = error.response.data.res;
            });

            

            
        }
    },

    computed: {
    getRutina() {
      return this.rutina;
    }
  },
    data() {
      return {
        ejercicios : {},
        filas_restantes: 100 - this.rutina.detalles.length,
        dia: [],
        ejercicio_id: [],
        series: [],
        cantidad: [],

      }
    },
    // created() {
    //   // console.log(this.$route.params.rutina)
    // }
  }
</script>

<style scoped>
.cont{
  background: #dfdfdf;
  padding: 7em;
  border-radius: 0%;
  border-style: outset;
}
.design{
    margin-top:5%;
}
input[type=button], input[type=submit], input[type=reset]  {
  background-color: #56baed;
  border: none;
  color: white;
  padding: 15px 80px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  text-transform: uppercase;
  font-size: 13px;
  -webkit-box-shadow: 0 10px 30px 0 rgba(95,186,233,0.4);
  box-shadow: 0 10px 30px 0 rgba(95,186,233,0.4);
  -webkit-border-radius: 5px 5px 5px 5px;
  border-radius: 5px 5px 5px 5px;
  margin: 5px 20px 40px 20px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
</style>