<template>
  <div id="app">
    <router-link to="/"  v-if="!tokenEnAlmacenamientoLocal" style="color: white">Acceso</router-link> |
    <router-link to="/dashboard" style="color: white">Socios</router-link> |
    <router-link to="/ejercicioAgregar" style="color: white">Ejercicios</router-link> |
    <router-link to="/about" style="color: white">About</router-link>
<!--    <button @click="cerrarSesion">Cerrar Sesión</button>-->

    <router-view/>
  </div>
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>


<script>

import axios from "axios";

export default {

  components: {

  },
  methods:{
    cerrarSesion() {

      axios.post('/api/cerrarsesion')
          .then(response => {
            // Manejar la respuesta si es necesario
            console.log(response.data);
            // Redireccionar al usuario a la página de inicio de sesión u otra página
            // window.location.href = '/login';
          })
          .catch(error => {
            // Manejar cualquier error que ocurra durante la solicitud
            console.error('Error al cerrar sesión:', error);
          });
    }
  },

  computed: {
    tokenEnAlmacenamientoLocal() {
      // Verificar si hay un token en el almacenamiento local
      return localStorage.getItem('token');
    }
  }
}
</script>