
<template>

   <div class="container cont">
      

<div class="row">

<div class="col-md-12">

  <img src="/imagenes/logo.png" alt="Descripción de la imagen" class="logo">


<router-link to="/socioAgregar" class="card-link">
<button type="button" class="btn btn-primary boton_quality">Agregar Socio</button>
</router-link> || 

<router-link to="/ejercicioAgregar" class="card-link">
<button type="button" class="btn btn-primary boton_quality">Agregar Ejercicio</button>
</router-link> ||

<router-link to="/ejercicioEditar" class="card-link">
  <button type="button" class="btn btn-primary boton_quality">Editar Ejercicio</button>
</router-link>

<br>
<br>

<input type="text" v-model="buscar" class="form-control" placeholder="Apellido"/>

</div>

</div>

<div class="row mt-3">
    <div class="col-md-2" v-for="socio in items" v-bind:key="socio.id">
            <div class="card mb-3">
                <router-link :to="{name: 'socio', params: {id: socio.id}}"><img class="card-img-top" v-bind:src="image + socio.foto" v-bind:alt="socio.foto" /> </router-link>
                <div class="card-body">
                    <h5 class="card-title mb-3">{{ socio.apellidos }}</h5>
                    <p class="card-text" style="color:#198754" v-if="socio.estado == '1'"><strong><b-icon-check-lg></b-icon-check-lg></strong></p>
                    <p class="card-text" style="color:#dc3545" v-if="socio.estado == '0'"><strong><b-icon-x-lg></b-icon-x-lg></strong></p>
                    
                    <p class="card-text"><strong>Nombres:</strong> {{ socio.nombres }}</p>
                    <p class="card-text"><strong>DNI:</strong> {{ socio.dni }}</p> 
                </div>
            </div>
    </div>

</div>



   </div>
        

</template>

<script>
// import Header from '@/components/HeaderDash.vue';
// import Footer from '@/components/FooterDash.vue';

import {Global} from '@/Global'

import axios from "axios";


export default{
    name:'DashboardView',
    components:{
      
    },
    data(){
        return{
            usuario : {},
            socios : [],
            error: false,
            error_msg: "",
            image: 'http://apiquality.webssdigital.com.ar/',
            buscar:''
        }
        
    },
    mounted(){
        if (localStorage.getItem('token')){
            this.usuario = JSON.parse(localStorage.getItem('perfilUsuario'))
            // console.log(this.usuario);
            this.getSocios();
        }
    },
    methods: {
        getSocios(){
            let config = {
                headers: {
                    // 'Authorization': 'Bearer ' + Global.token
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                },
            }
             axios.get(Global.url('socios'), config)
             
            .then(res=>{
                    // console.log(res.data.data)
                if(res.status == 200){
                    this.socios=res.data.data;
                    // console.log(this.socios);
                }
                    
            }).catch(error => {
            // console.log(error.response.data.message)
            this.error = true;
            this.error_msg = error.response.data.res;
            });
        },
        cerrarSesion() {
          let config = {
            headers: {
              // 'Authorization': 'Bearer ' + Global.token
              'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
          }
          const token = localStorage.getItem('token'); // Obtener el token almacenado localmente
          if (!token) {
            console.error('No se encontró el token de autenticación.');
            return;
          }

          axios.post(
              'http://apiquality.webssdigital.com.ar/api/cerrarsesion',
              token,
              config
          )


              .then(response => {
                // Manejar la respuesta si es necesario
                console.log(response.data);
                // Redireccionar al usuario a la página de inicio de sesión u otra página
                // window.location.href = '/login';
              })
              .catch(error => {
                // Manejar cualquier error que ocurra durante la solicitud
                console.error('Error al cerrar sesión:', error);
              });
        },

    },
    computed: {
        items() {
            return this.socios.filter(socio => {
            return socio.apellidos.toLowerCase().includes(this.buscar.toLowerCase());
        });
    },
    }
  
}
</script>

<style >
#app{
  background: rgb(255,122,23);
  background: radial-gradient(circle, rgba(255,122,23,1) 0%, rgba(0,0,0,1) 41%);
}
.selector-for-some-widget {
  box-sizing: content-box;
}
.logo{
  width: 150px;
  display: block;
}
.boton_quality{
  background: #000 !important;
  border: none !important;
}

</style>