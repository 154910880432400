<template>
  <div class="container cont">
    <img src="/imagenes/logo_t.png" alt="Descripción de la imagen" class="logo">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"> <router-link :to="'/dashboard'">Volver</router-link></li>
      </ol>
    </nav>
    <h1>Editar Ejercicio </h1>


    <form v-on:submit.prevent = "editarRutina">

      <div class="design">

        <div class="row">

          <div class="col">
            <div class="form-outline">
              <label class="form-label">Ejercicio</label>
              <select class="form-select" required v-model="ejercicio_id" @change="cargarDetallesEjercicio">
                <option disabled value="">Seleccionar Ejercicio</option>
                <option v-for="ejercicio in ejercicios" :value="ejercicio.id" :key="ejercicio.id">{{ ejercicio.nombre }}</option>
              </select>
            </div>
          </div>

        </div>

        <!-- Aquí agregamos los campos para mostrar los detalles del ejercicio -->
        <div class="row mt-4" v-if="ejercicioSeleccionado">


          <div class="col">
            <!-- Email input -->
            <div class="form-outline">
              <select class="form-select" v-model="ejercicioSeleccionado.categoria_id" required  @change="actualizarCategoria">
                <option disabled value="">Seleccionar Categoria</option>
                <option v-for="categoria in categorias" :value="categoria.id" :key="categoria.id">{{ categoria.nombre }}</option>
              </select>
              <label class="form-label">Categoría</label>
            </div>
          </div>


          <div class="col">
            <label for="selectImage">Seleccionar imagen:</label>
            <select v-model="ejercicioSeleccionado.imagen">
              <option v-for="index in 32" :key="index" :value="`${index}.jpg`" :style="{ backgroundImage: `url('/imagenes/${index}.jpg')` }">
                Imagen {{ index }}
              </option>
            </select>
            <div>
              <img :src="'/imagenes/' + ejercicioSeleccionado.imagen" alt="Imagen seleccionada" height="200" width="200">
            </div>
          </div>
        </div>


      </div>

      <div class="row" v-if="ejercicioSeleccionado">

        <div class="col">
          <!-- Name input -->
          <div class="form-outline">
            <input type="comentario"  class="form-control" v-model="ejercicioSeleccionado.comentario">
            <label class="form-label">Comentario sobre el ejercicio</label>
          </div>
        </div>
        <div class="col">
          <!-- Name input -->
          <div class="form-outline">
            <input type="comentario"  class="form-control" v-model="ejercicioSeleccionado.video_url">
            <label class="form-label">Link video youtube</label>
          </div>
        </div>

      </div>

      <div class="row">
        <div class="col">
          <input type="submit" class="fadeIn fourth" value="Guardar Rutina">
        </div>
      </div>
    </form>

  </div>
</template>

<script>
// import {Global} from '@/Global'

import axios from "axios";
import {Global} from "@/Global";

const Swal = require('sweetalert2')

export default {
  name:'EjercicioEditarView',
  mounted(){
    if (localStorage.getItem('token')){
      this.ejercicios = JSON.parse(localStorage.getItem('perfilUsuario'))
      // console.log(this.usuario);
      this.getEjercicios();
      this.getCategorias();

    }
  },
  methods: {
    actualizarCategoria() {
      // Obtener la categoría seleccionada
      const categoriaSeleccionada = this.categorias.find(categoria => categoria.id === this.ejercicioSeleccionado.categoria_id);
      // Asignar la categoría seleccionada al ejercicio
      this.ejercicioSeleccionado.categoria = categoriaSeleccionada;
    },
    getCategorias(){
      let config = {
        headers: {
          // 'Authorization': 'Bearer ' + Global.token
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
      }
      axios.get(Global.url('categorias'), config)

          .then(res=>{
            // console.log(res.data.data)
            if(res.status == 200){
              this.categorias=res.data.data;
              // console.log(this.ejercicios);
            }

          }).catch(error => {
        // console.log(error.response.data.message)
        this.error = true;
        this.error_msg = error.response.data.res;
      });
    },
    getEjercicios(){
      let config = {
        headers: {
          // 'Authorization': 'Bearer ' + Global.token
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
      }
      axios.get(Global.url('ejercicios'), config)

          .then(res=>{
            // console.log(res.data.data)
            if(res.status == 200){
              this.ejercicios=res.data.data;
              // console.log(this.ejercicios);
            }

          }).catch(error => {
        // console.log(error.response.data.message)
        this.error = true;
        this.error_msg = error.response.data.res;
      });
    },
  cargarDetallesEjercicio() {
    this.getEjercicios();
    // Buscar el ejercicio seleccionado en la lista de ejercicios
    const ejercicioSeleccionado = this.ejercicios.find(ejercicio => ejercicio.id === this.ejercicio_id);
    // Asignar los detalles del ejercicio seleccionado a la variable correspondiente
    this.ejercicioSeleccionado = ejercicioSeleccionado;
  },
    editarRutina() {

      const config = {
        headers: {Authorization: 'Bearer ' + localStorage.getItem('token')}
      };

      const bodyParameters = {
        id: this.ejercicioSeleccionado.id,
        nombre: this.ejercicioSeleccionado.nombre,
        imagen: this.ejercicioSeleccionado.imagen,
        comentario: this.ejercicioSeleccionado.comentario,
        video_url: this.ejercicioSeleccionado.video_url,
        categoria_id: this.ejercicioSeleccionado.categoria_id,

      };

      axios.put(
          'http://apiquality.webssdigital.com.ar/api/ejercicios/' + this.ejercicioSeleccionado.id,
          bodyParameters,
          config
      ).then(res => {
        console.log(res.data)
        if (res.status == 202) {


          Swal.fire({
            title: 'Ejercicios',
            text: 'Ejercicio editado correctamente',
            icon: 'success',
            confirmButtonText: 'Cool'
          })

        }

      })
          .catch(error => {
            console.log(error)
            // this.error = true;
            // this.error_msg = error.response.data.res;
          });
    }

  },

  computed: {

  },
  data() {
    return {
      ejercicios: [],
      ejercicio_id: '',
      ejercicioSeleccionado: null // Variable para almacenar los de


    }
  },

}
</script>

<style scoped>
.fixed-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
}

.cont{
  background: #dfdfdf;
  padding: 7em;
  border-radius: 0%;
  border-style: outset;
}
.design{
  margin-top:5%;
}
input[type=button], input[type=submit], input[type=reset]  {
  background-color: #56baed;
  border: none;
  color: white;
  padding: 15px 80px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  text-transform: uppercase;
  font-size: 13px;
  -webkit-box-shadow: 0 10px 30px 0 rgba(95,186,233,0.4);
  box-shadow: 0 10px 30px 0 rgba(95,186,233,0.4);
  -webkit-border-radius: 5px 5px 5px 5px;
  border-radius: 5px 5px 5px 5px;
  margin: 5px 20px 40px 20px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
</style>