<template>
  <div class="container cont">
    <img src="/imagenes/logo_t.png" alt="Descripción de la imagen" class="logo">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"> <router-link :to="'/socio/' + user_id">Volver</router-link></li>
      </ol>
    </nav>
    <h1>Rutina de entrenamiento </h1>

    <div class="fixed-button">
      <button @click="scrollToLastRow" class="btn btn-primary">Ir a la última fila</button>
    </div>

    <form v-on:submit.prevent = "agregarRutina">

        <div class="design">
            <div class="row">
              <div class="col-12" style="margin-bottom: 20px">
                <!-- Name input -->
                <div class="form-outline" >
                  <label class="form-label">Seleccionar Socio</label>
                  <select class="form-select" v-model="user_id " required>
                    <option disabled value="">Seleccionar socio</option>
                    <!-- Iterar sobre los socios y generar opciones -->
                    <option v-for="(socio, index) in socios" :value="socio.id"  :key="index">{{ socio.apellidos }} {{ socio.nombres }}</option>
                  </select>
                </div>
              </div>

              <div class="col-12" style="margin-bottom: 20px">
                <!-- Name input -->
                <div class="form-outline">
                  <label class="form-label">Objetivos</label>
                  <input type="text" class="form-control" v-model="objetivos">
                </div>
              </div>

              <div class="col-12" style="margin-bottom: 20px">
                <!-- Name input -->
                <div class="form-outline">
                  <label class="form-label">Observaciones</label>
                  <input type="text" class="form-control" v-model="observaciones">
                </div>
              </div>

            </div>





          <div>
            <div v-for="(row, rowIndex) in rows" :key="rowIndex">
              <div class="row">
                <div class="col-2">
                  <div class="form-outline">
                    <label class="form-label">Día Número</label>
                    <select class="form-select" v-model="dia[rowIndex]" :required="rowIndex === 0">
                      <option disabled value="">Seleccionar día</option>
                      <option v-for="i in 5" :key="i" :value="i">{{ i }}</option>
                    </select>
                  </div>
                </div>

                <div class="col-6">
                  <div class="form-outline">
                    <label class="form-label">Ejercicio</label>
<!--                    <select class="form-select" :required="rowIndex === 0" v-model="ejercicio_id[rowIndex]">-->
<!--                      <option disabled value="">Seleccionar Ejercicio</option>-->
<!--                      <option v-for="ejercicio in ejercicios" :value="ejercicio.id" :key="ejercicio.id">{{ ejercicio.nombre }}</option>-->
<!--                    </select>-->

                    <v-select
                        class=""
                        :required="rowIndex === 0"
                        v-model="ejercicio_id[rowIndex]"
                        :options="ejercicios"
                        label="nombre"
                        placeholder="Seleccionar Ejercicio"
                        @input="updateExerciseId(selectedExercise.id, rowIndex)"
                    />

                  </div>
                </div>

                <div class="col-2">
                  <div class="form-outline">
                    <label class="form-label">Cant. Series</label>
                    <select class="form-select" :required="rowIndex === 0" v-model="series[rowIndex]">
                      <option disabled value="">Seleccionar cantidad de series</option>
                      <option v-for="i in 8" :key="i" :value="i">{{ i }}</option>
                    </select>
                  </div>
                </div>

                <div class="col-2">
                  <div class="form-outline">
                    <label class="form-label">Cant. Repeticiones</label>
                    <input type="number" class="form-control" v-model="cantidad[rowIndex]">
                  </div>
                </div>
              </div>
              <hr />
            </div>
          </div>




        </div>

        <div class="row">
          <div class="col">
            <input type="submit" class="fadeIn fourth" value="Guardar Rutina">
          </div>
        </div>
    </form>
  </div>
</template>

<script>
// import {Global} from '@/Global'

import axios from "axios";
import {Global} from "@/Global";
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';

const Swal = require('sweetalert2')


  export default {
    name:'RutinaAgregarView',
    components: {
      'v-select': vSelect
    },
    mounted(){
      if (localStorage.getItem('token')){
        this.ejercicios = JSON.parse(localStorage.getItem('perfilUsuario'))
        // console.log(this.usuario);
        this.getEjercicios();
        this.getSocios();
      }
    },
    methods: {
      updateExerciseId(exerciseId, rowIndex) {
        // Almacenar solo el ID del ejercicio en ejercicio_id en la misma posición que el ejercicio seleccionado
        this.$set(this.ejercicio_id, rowIndex, exerciseId);
      },
      scrollToLastRow() {
        // Encontrar el último elemento visible dentro del formulario
        const lastInputElement = document.querySelector('.row:last-of-type input:last-of-type');
        if (lastInputElement) {
          // Enfocar el último elemento visible
          lastInputElement.focus();
          // Desplazar la página hacia abajo después de un pequeño retraso
          setTimeout(() => {
            window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
          }, 100);
        }
      },

      getEjercicios(){
        let config = {
          headers: {
            // 'Authorization': 'Bearer ' + Global.token
            'Authorization': 'Bearer ' + localStorage.getItem('token')
          },
        }
        axios.get(Global.url('ejercicios'), config)

            .then(res=>{
              // console.log(res.data.data)
              if(res.status == 200){
                this.ejercicios=res.data.data;
                // Convertimos el objeto a un array usando Object.values()
                // this.ejercicios = Object.values(this.ejercicios);
                // console.log(this.ejercicios);
              }

            }).catch(error => {
          // console.log(error.response.data.message)
          this.error = true;
          this.error_msg = error.response.data.res;
        });
      },
      getSocios(){
        let config = {
          headers: {
            // 'Authorization': 'Bearer ' + Global.token
            'Authorization': 'Bearer ' + localStorage.getItem('token')
          },
        }
        axios.get(Global.url('socios'), config)

            .then(res=>{
              // console.log(res.data.data)
              if(res.status == 200){
                this.socios=res.data.data;
                console.log(this.socios);
              }

            }).catch(error => {
          // console.log(error.response.data.message)
          this.error = true;
          this.error_msg = error.response.data.res;
        });
      },
      agregarRutina(){

        const config = {
          headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
        };

        const bodyParameters = {

          user_id : this.user_id,
          objetivos : this.objetivos,
          observaciones : this.observaciones,
          dia : this.dia,
          ejercicio_id : this.ejercicio_id,
          series : this.series,
          cantidad : this.cantidad,

        };

        axios.post(
            'http://apiquality.webssdigital.com.ar/api/rutinas',
            bodyParameters,
            config
        ).then(() => {
          Swal.fire({
            title: 'Rutina',
            text: 'Rutina agregada correctamente',
            icon: 'success',
            confirmButtonText: 'Cool',
          }).then(() => this.$router.push({ name: 'dashboard' }))
        }).catch(error => {
          console.log(error)
          // this.error = true;
          // this.error_msg = error.response.data.res;
        });


      }

    },

    computed: {

    },
    data() {
      return {
        rows: Array(100).fill({}),
        objetivos: '',
        observaciones:'',
        ejercicios : [],
        socios : {},
        user_id: '',
        dia: [],
        ejercicio_id: [],
        series: [],
        cantidad: [],
        selectedExercise: null,
      }
    },
    created() {
      // Accedemos al parámetro "id" desde la ruta
      this.user_id = this.$route.params.id;
    }
  }
</script>

<style scoped>
.fixed-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
}

.cont{
  background: #dfdfdf;
  padding: 7em;
  border-radius: 0%;
  border-style: outset;
}
.design{
    margin-top:5%;
}
input[type=button], input[type=submit], input[type=reset]  {
  background-color: #56baed;
  border: none;
  color: white;
  padding: 15px 80px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  text-transform: uppercase;
  font-size: 13px;
  -webkit-box-shadow: 0 10px 30px 0 rgba(95,186,233,0.4);
  box-shadow: 0 10px 30px 0 rgba(95,186,233,0.4);
  -webkit-border-radius: 5px 5px 5px 5px;
  border-radius: 5px 5px 5px 5px;
  margin: 5px 20px 40px 20px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
</style>