<template>
  <div class="container cont">
    <img src="/imagenes/logo_t.png" alt="Descripción de la imagen" class="logo">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"> <router-link :to="'/socio/' + socio.id">Volver</router-link></li>
      </ol>
    </nav>
    <h1>Editar: {{socio.apellidos}} {{socio.nombres}} </h1>


    <form v-on:submit.prevent = "editarSocio"> 

        <div class="design">
            <div class="row">
                <div class="col">
                    <!-- Name input -->
                    <div class="form-outline" >
                    <input type="text" class="form-control" v-model="getSocio.apellidos" required>
                    <label class="form-label" for="form8Example1">Apellidos</label>
                    </div>
                </div>
                <div class="col">
                    <!-- Email input -->
                    <div class="form-outline">
                    <input type="text" class="form-control" v-model="getSocio.nombres" required>
                    <label class="form-label">Nombres</label>
                    </div>
                </div>
                <div class="col">
                    <!-- Email input -->
                    <div class="form-outline">
                        <input type="text" class="form-control" v-model="getSocio.dni" required>
                    <label class="form-label">DNI</label>
                    </div>
                </div>
                <div class="col">
                    <!-- Name input -->
                    <div class="form-outline">
                        <select v-model="getSocio.genero" class="form-control" required>
                            <option value=""></option>
                            <option value="Masculino">Masculino</option>
                            <option value="Femenino" selected>Femenino</option>
                        </select>
                        
                    <label class="form-label">Género</label>
                    </div>
                </div>
             
            </div>

            <hr />

            <div class="row">
                <div class="col">
                    <!-- Name input -->
                    <div class="form-outline">
                        <input type="date" class="form-control" v-model="getSocio.fecha_nacimiento">
                        <label class="form-label">Fecha de Nacimiento</label>
                    </div>
                </div>
                <div class="col">
                    <!-- Name input -->
                    <div class="form-outline">
                        <input type="email"  class="form-control" v-model="getSocio.email">
                        <label class="form-label">Email</label>
                    </div>
                </div>
                <div class="col">
                    <!-- Name input -->
                    <div class="form-outline">
                        <input type="text" class="form-control" v-model="getSocio.domicilio">
                        <label class="form-label">Domicilio</label>
                    </div>
                </div>
                <div class="col">
                    <!-- Email input -->
                    <div class="form-outline">
                        <input type="number" class="form-control" v-model="getSocio.telefono" placeholder="2266000000">
                        <label class="form-label">Nº Celular</label> <br>
                         <span style="color:red">Sin el 15 - Ej: 2266123456</span>
                    </div>
                </div>
            </div>

            <hr />

            <div class="row">
                <!-- <div class="col">
                    <div class="form-outline">
                        <input type="file" class="form-control">
                        <label class="form-label">Foto</label>
                    </div>
                </div> -->
                <div class="col">
                    <!-- Name input -->
                    <div class="form-outline">
                        <input type="text"  class="form-control" v-model="getSocio.altura" required>
                        <label class="form-label">Altura (m)</label> <br>  
                        <span style="color:red">Ej: 1,70</span>

                    </div>
                </div>
                <div class="col">
                    <!-- Name input -->
                    <div class="form-outline">
                        <input type="text" class="form-control" v-model="getSocio.peso" required>
                        <label class="form-label">Peso (kg)</label> <br>  
                        <span style="color:red">Ej: 72.3</span>

                    </div>
                </div>
                <div class="col">
                    <!-- Email input -->
                    <div class="form-outline">
                        <input type="text" class="form-control" v-model="getSocio.tipo_sangre" required>
                        <label class="form-label">Tipo de Sangre</label> <br>
                        <span style="color:red">Ej: A+</span>

                    </div>
                </div>

            </div>
            <hr />

            <div class="row">
                <div class="col">
                    <input type="submit" class="fadeIn fourth" value="Guardar">
                </div>
            </div>

        </div>
    </form>
  </div>
</template>

<script>
// import {Global} from '@/Global'

import axios from "axios";

const Swal = require('sweetalert2') 

  export default {
    name:'SocioEditarView',
    props: ['socio'],
    methods: {
        editarSocio(){
            let formato_fecha_nacimiento = this.socio.fecha_nacimiento.split("-").reverse().join("-");

            const config = {
                headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
            };

            const bodyParameters = {
                nombres : this.socio.nombres,
                apellidos : this.socio.apellidos,
                genero : this.socio.genero,
                dni : this.socio.dni,
                domicilio : this.socio.domicilio,
                telefono : this.socio.telefono,
                fecha_nacimiento : formato_fecha_nacimiento,
                altura : this.socio.altura,
                peso : this.socio.peso,
                tipo_sangre : this.socio.tipo_sangre,
                foto : this.socio.foto,
                email : this.socio.email,
                estado : this.socio.estado,
                
            };

            axios.put( 
            'http://apiquality.webssdigital.com.ar/api/socios/'+this.socio.id,
            bodyParameters,
            config
            ).then(res=>{
                    console.log(res.data.msj)
                if(res.status == 202){
                   
            
                         Swal.fire({
                        title: 'Socios',
                        text: 'Socio editado correctamente',
                        icon: 'success',
                        confirmButtonText: 'Cool'
                        })
                    
                }
                    
            })
            .catch(error => {
            console.log(error)
            // this.error = true;
            // this.error_msg = error.response.data.res;
            });

            

            
        }
    },
   
    computed: {
    getSocio() {
      return this.socio;
    }
  },
    data() {
      return {
        apellido: '',
        nombres: '',
        genero:'',
        dni:'',
        domicilio:'',
        telefono:'',
        fecha_nacimiento:'',
        altura:'',
        peso:'',
        tipo_sangre:'',
        foto:'',
        password:'123456'
    

      }
    }
  }
</script>

<style scoped>
.cont{
  background: #dfdfdf;
  padding: 7em;
  border-radius: 0%;
  border-style: outset;
}
.design{
    margin-top:5%;
}
input[type=button], input[type=submit], input[type=reset]  {
  background-color: #56baed;
  border: none;
  color: white;
  padding: 15px 80px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  text-transform: uppercase;
  font-size: 13px;
  -webkit-box-shadow: 0 10px 30px 0 rgba(95,186,233,0.4);
  box-shadow: 0 10px 30px 0 rgba(95,186,233,0.4);
  -webkit-border-radius: 5px 5px 5px 5px;
  border-radius: 5px 5px 5px 5px;
  margin: 5px 20px 40px 20px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
</style>