<template>
  <div class="service-area-2 space overflow-hidden" style="background: white;" >
    <li class="breadcrumb-item"> <router-link :to="'/dashboard'">Volver</router-link></li>
    <button color="primary" dark class="mb-2" @click="generarRutinaPdf()">Generar PDF</button>

    <div class="container" id="element-to-print">

        <div class="row">
          <img src="/imagenes/logo_t.png" alt="Descripción de la imagen" style="width: 300px">

          <p>Apellido y Nombre: {{socio.apellidos}} {{socio.nombres}} </p>
          <p>Fecha de Inicio: {{rutina.fecha}}</p>
          <p>Objetivos: {{rutina.objetivos}}</p>
          <p>Observaciones: {{rutina.observaciones}}</p>
        </div>

        <div class="row">
          <div class="col-4"  v-for="(grupoDetalles, dia) in agruparDetallesPorDia(rutina.detalles)" :key="dia">
            <h6 class="dia">Día {{ dia }}</h6>

            <table  class="custom-table">
              <thead>
              <tr>
                <th>Ejercicio</th>
                <th>Series</th>
                <th>Cantidad</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="detalle in grupoDetalles" :key="detalle.id_detalle">
                <td>{{ detalle.ejercicio.nombre }}</td>
                <td>{{ detalle.series }}</td>
                <td>{{ detalle.cantidad }}</td>
              </tr>
              </tbody>

            </table>
          </div>

        </div>

    </div>

  </div>


</template>

<script>
import html2pdf from "html2pdf.js";

export default {
  props: ['rutina', 'socio'],

  methods: {
    agruparDetallesPorDia(detalles) {
      const grupos = {};
      detalles.forEach(detalle => {
        const dia = detalle.dia;
        if (!grupos[dia]) {
          grupos[dia] = [];
        }
        grupos[dia].push(detalle);
      });
      return grupos;
    },
    generarRutinaPdf()
    {
      var element = document.getElementById('element-to-print');
      var opt = {
        margin:       0.5,
        filename:     'rutina.pdf',
        image:        { type: 'jpeg', quality: 1 },
        html2canvas:  { scale: 3 },
        jsPDF:        { unit: 'in', format: 'a4', orientation: 'portrait' }
      };
      html2pdf().from(element).set(opt).save();
    },
  },



};


</script>

<style scoped>
/* Estilos específicos para esta vista previa */

@import url('../../public/assets/css/style.css');



/* Estilos para la tabla */
h6.dia{
  margin-top: 10px;
}
.custom-table th,
.custom-table td {
  border: 1px solid #dddddd; /* Borde de las celdas */
  padding: 8px; /* Espaciado interno de las celdas */
  text-align: left; /* Alineación del texto a la izquierda */
}

.custom-table th {
  background-color: #f2f2f2; /* Color de fondo de las celdas de encabezado */
}

/* Estilo para las filas impares (alternando colores) */
.custom-table tr:nth-child(odd) {
  background-color: #f9f9f9; /* Color de fondo de las filas impares */
}



.row {
  display: flex;
  flex-wrap: wrap;
}

.column {
  flex: 1; /* Hacer que cada columna ocupe el mismo espacio */
  padding: 10px;
  box-sizing: border-box;
}

.custom-table {
  width: 100%;
  border-collapse: collapse;
  font-size: 12px;
  margin: 0; /* Eliminar márgenes predeterminados */
}

</style>