<template>
  <div class="container cont">
    <img src="/imagenes/logo_t.png" alt="Descripción de la imagen" class="logo">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"> <router-link to="/dashboard">Volver</router-link></li>
      </ol>
    </nav>
    <h1>Agregar Ejercicio </h1>

    <form v-on:submit.prevent = "agregarEjercicio">

        <div class="design">
            <div class="row">
                <div class="col">
                    <!-- Name input -->
                    <div class="form-outline" >
                    <input type="text" class="form-control" v-model="nombre" required>
                    <label class="form-label" for="form8Example1">Nombre del Ejercicio</label>
                    </div>
                </div>
<!--                <div class="col">-->
<!--                    &lt;!&ndash; Email input &ndash;&gt;-->
<!--                    <div class="form-outline">-->
<!--                      <select v-model="imagen" required>-->
<!--                        <option v-for="i in 32" :value="`${i}.jpg`" :key="i">{{ i }}</option>-->
<!--                      </select>-->
<!--                      <label class="form-label">Nº de Imagen del Ejercicio</label>-->
<!--                    </div>-->
<!--                </div>-->

                <div class="col">
                  <label for="selectImage">Seleccionar imagen:</label>
                  <select v-model="imagen">
                    <option v-for="index in 32" :key="index" :value="`${index}.jpg`" :style="{ backgroundImage: `url('/imagenes/${index}.jpg')` }">
                      Imagen {{ index }}
                    </option>
                  </select>
                  <div>
                    <img :src="'/imagenes/' + imagen" alt="Imagen seleccionada" height="200" width="200">
                  </div>
                </div>

                <div class="col">
                    <!-- Email input -->
                    <div class="form-outline">
                      <select class="form-select" v-model="categoria_id" required>
                        <option disabled value="">Seleccionar Categoria</option>
                        <option v-for="categoria in categorias" :value="categoria.id" :key="categoria.id">{{ categoria.nombre }}</option>
                      </select>
                    <label class="form-label">Categoría</label>
                    </div>
                </div>

            </div>

            <hr />

            <div class="row">

                <div class="col">
                    <!-- Name input -->
                    <div class="form-outline">
                        <input type="comentario"  class="form-control" v-model="comentario">
                        <label class="form-label">Comentario sobre el ejercicio</label>
                    </div>
                </div>
              <div class="col">
                <!-- Name input -->
                <div class="form-outline">
                  <input type="comentario"  class="form-control" v-model="video_url">
                  <label class="form-label">Link video youtube</label>
                </div>
              </div>

            </div>

            <hr />


            <div class="row">
                <div class="col">
                    <input type="submit" class="fadeIn fourth" value="Agregar">
                </div>
            </div>

        </div>
    </form>
  </div>
</template>

<script>
// import {Global} from '@/Global'

import axios from "axios";
import {Global} from "@/Global";

const Swal = require('sweetalert2') 

  export default {
    name:'EjercicioAgregarView',
    mounted(){
      if (localStorage.getItem('token')){
        this.categorias = JSON.parse(localStorage.getItem('perfilUsuario'))
        // console.log(this.usuario);
        this.getCategorias();
      }
    },
    methods: {
        getCategorias(){
          let config = {
            headers: {
              // 'Authorization': 'Bearer ' + Global.token
              'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
          }
          axios.get(Global.url('categorias'), config)

              .then(res=>{
                // console.log(res.data.data)
                if(res.status == 200){
                  this.categorias=res.data.data;
                  // console.log(this.ejercicios);
                }

              }).catch(error => {
            // console.log(error.response.data.message)
            this.error = true;
            this.error_msg = error.response.data.res;
          });
        },
        agregarEjercicio(){

            const config = {
                headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
            };

            const bodyParameters = {
              nombre: this.nombre,
              imagen: this.imagen,
              categoria_id: this.categoria_id,
              comentario: this.comentario,
              video_url: this.video_url,

            };

            axios.post( 
            'http://apiquality.webssdigital.com.ar/api/ejercicios',
            bodyParameters,
            config
            ).then(
                function () 
                {
                    Swal.fire({
                        title: 'Ejercicio',
                        text: 'Ejercicio agregado correctamente',
                        icon: 'success',
                        confirmButtonText: 'Cool',
                        
                        }).then(() => {window.location.href = 'http://localhost:8080' +'/dashboard'})
                    

                }
            
            )
            .catch(error => {
            console.log(error)
            // this.error = true;
            // this.error_msg = error.response.data.res;
            });

            
        }
    },
    computed: {
    },
    data() {
      return {
        nombre: '',
        imagen: '',
        categoria_id: '',
        comentario: '',
        video_url: '',

      }
    }
  }
</script>

<style scoped>

select option {
  padding-left: 50px; /* Asegura que haya espacio para la imagen */
  background-size: contain; /* Ajusta el tamaño de la imagen */
  background-repeat: no-repeat; /* Evita que se repita la imagen */
}

.cont{
  background: #dfdfdf;
  padding: 7em;
  border-radius: 0%;
  border-style: outset;
}
.design{
    margin-top:5%;
}
input[type=button], input[type=submit], input[type=reset]  {
  background-color: #56baed;
  border: none;
  color: white;
  padding: 15px 80px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  text-transform: uppercase;
  font-size: 13px;
  -webkit-box-shadow: 0 10px 30px 0 rgba(95,186,233,0.4);
  box-shadow: 0 10px 30px 0 rgba(95,186,233,0.4);
  -webkit-border-radius: 5px 5px 5px 5px;
  border-radius: 5px 5px 5px 5px;
  margin: 5px 20px 40px 20px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
</style>