import { render, staticRenderFns } from "./EjercicioEditarView.vue?vue&type=template&id=6ef530d0&scoped=true&"
import script from "./EjercicioEditarView.vue?vue&type=script&lang=js&"
export * from "./EjercicioEditarView.vue?vue&type=script&lang=js&"
import style0 from "./EjercicioEditarView.vue?vue&type=style&index=0&id=6ef530d0&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ef530d0",
  null
  
)

export default component.exports