<template>
    <div class="container">
      <img src="/imagenes/logo.png" alt="Descripción de la imagen" class="logo">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"> <router-link to="/dashboard">SOCIOS</router-link></li>
                <li class="breadcrumb-item active" aria-current="page">{{socio.apellidos}} {{socio.nombres}}</li>
            </ol>
        </nav>

        <div class="alert alert-info"  role="alert" v-if="mensaje">
        {{mensaje}}
        </div>

        <div class="success" style="width: 12rem" v-if="socio.estado == '1'" >
            <h3 class="card-title" style="color:#198754">Habilitado<b-icon-check-lg class="margen_izquierdo"></b-icon-check-lg></h3>
        </div>
        <div class="warn" style="width: 12rem;" v-if="socio.estado == '0'">
            <h3 class="card-title" style="color:#dc3545">Deshabilitado<b-icon-x-lg></b-icon-x-lg></h3>
        </div>

        
        <br>
        <div class="card" style="width: 12rem;">
            <img class="card-img-top" :src='image+socio.foto' :alt='socio.nombres'>
            <h5 class="card-title" ><b-icon-gender-female  v-if="socio.genero == 'FEMENINO'"></b-icon-gender-female> <b-icon-gender-male  v-if="socio.genero == 'MASCULINO'"></b-icon-gender-male></h5>

        </div>
        <br>
        
        


        <div class="card-group">
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title">{{socio.apellidos}} {{socio.nombres}} </h5>
                </div>
                <ul class="list-group list-group-flush" id="element-to-print">
                    <li class="list-group-item"><strong>DNI:</strong> {{socio.dni}}</li>
                    <li class="list-group-item"><strong>DOMICILIO:</strong> {{socio.domicilio}}</li>
                    <li class="list-group-item"><strong>Nº TEL:</strong> {{socio.telefono}}</li>
                    <li class="list-group-item"><strong>FECHA NACIMIENTO:</strong> {{socio.fecha_nacimiento}}</li>
                    <li class="list-group-item"><strong>A:</strong> {{socio.altura}} - <strong>PESO:</strong> {{socio.peso}} - <strong>TIPO SANGRE:</strong> {{socio.tipo_sangre}}</li>
                    <li class="list-group-item"><strong>EMAIL:</strong> {{socio.email}}</li>
                </ul>
                <div class="card-body">

                    <form v-on:submit.prevent = "estado"> 
                        <b-button type="submit" variant="danger" v-if="socio.estado == '1'" class="boton_quality">Deshabilitar</b-button>
                        <b-button type="submit" variant="success" v-if="socio.estado == '0'" class="boton_quality">Habilitar</b-button>
                    </form>
                    <br> 
                   

                <router-link :to="{name: 'socioEditar', params: {socio: socio}}">
                  <button type="button" class="btn btn-primary boton_quality" variant="info">Editar</button>
                </router-link>

               

                </div>
            </div>
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title">Rutinas</h5>
                    <div class="card-body">

                      <router-link :to="{name: 'rutinaAgregar', params: {id: socio.id}}" class="enlaces">
                        <p class="card-text" style="color:white"><b-button class="boton_quality">Agregar</b-button></p>
                      </router-link>
                    </div>
                    <div v-for="rutina in rutinas" :key="rutina.id">

                        <div style="background: #25d949;color: white; margin-bottom: 5px;"  v-if="rutina.estado == '1'">
                            <router-link :to="{name: 'rutinaEditar', params: {rutina: rutina}}" class="enlaces">
                                <p class="card-text" style="color:white">{{rutina.fecha}} / Editar</p>
                            </router-link>
                          <button variant="info" @click="mostrarVistaPrevia(rutina)">Ver</button>
                        </div>

                        <div style="background: rgb(242, 55, 12);color: white; margin-bottom: 5px;"   v-if="rutina.estado == '0'">
                            <router-link :to="{name: 'rutinaEditar', params: {rutina: rutina}}" class="enlaces">
                                <p class="card-text" style="color:white">{{rutina.fecha}} / Editar</p>
                            </router-link>
                            <button variant="info" @click="mostrarVistaPrevia(rutina)">Ver</button>

                        </div>
                    
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-body">
                <h5 class="card-title">Pagos</h5>
                <!-- <p class="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This card has even longer content than the first to show that equal height action.</p>
                <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p> -->
                </div>
            </div>
        </div>
        
        <br>

        <Footer/>
     

    </div>
</template>

<script>
import Footer from '@/components/FooterDash.vue';


import {Global} from '@/Global'

import axios from "axios";


export default{
    name:'SocioView',
    components:{
        Footer
    },
    data(){
        return{
            usuario : {},
            socio : [],
            rutinas: {},
            image: 'http://apiquality.webssdigital.com.ar/',
            error: false,
            error_msg: "",
            est: "",
            mensaje:"",
            color_mensaje:"",
            rol:""
        }
        
    },
    mounted(){
        if (localStorage.getItem('token')){
            let id = this.$route.params.id;
            // console.log(id);
            this.usuario = JSON.parse(localStorage.getItem('perfilUsuario'))
            // console.log(this.usuario);
            this.getSocio(id);
        }
    },
    methods: {
      mostrarVistaPrevia(rutina) {
        const socio = this.socio; // Asigna el objeto socio correspondiente
        this.$router.push({ name: 'VistaPrevia', params: { rutina: rutina, socio: socio } });
      },


        getSocio(id){
            let config = {
                headers: {
                    // 'Authorization': 'Bearer ' + Global.token
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                },
            }
             axios.get(Global.url('socios/'+id), config)
             
            .then(res=>{
                    // console.log(res.data)
                if(res.status == 200){
                    this.socio=res.data.data;
                    this.rutinas=res.data.data.rutinas
                    // console.log(this.socio.rutinas);
                }
                    
            }).catch(error => {
            console.log(error.response.data.message)
            this.error = true;
            this.error_msg = error.response.data.res;
            });
        },
        estado(){
            let formato_fecha_nacimiento = this.socio.fecha_nacimiento.split("-").reverse().join("-");

            if(this.socio.estado == "0")
            {
                this.est = "1"
            }else{
                this.est = "0"
            }

            if(this.socio.id == "1")
            {
                this.rol = "1"
            }else{
                this.rol = "2"
            }
            const config = {
                headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
            };

            const bodyParameters = {
                nombres : this.socio.nombres,
                apellidos : this.socio.apellidos,
                genero : this.socio.genero,
                dni : this.socio.dni,
                domicilio : this.socio.domicilio,
                telefono : this.socio.telefono,
                fecha_nacimiento : formato_fecha_nacimiento,
                altura : this.socio.altura,
                peso : this.socio.peso,
                tipo_sangre : this.socio.tipo_sangre,
                foto : this.socio.foto,
                email : this.socio.email,
                password : this.socio.password,
                estado : this.est,
                roles : [
                    this.rol
                ]
            };

            axios.put( 
            'http://apiquality.webssdigital.com.ar/api/socios/'+this.socio.id,
            bodyParameters,
            config
            ).then(res=>{
                    // console.log(res.data.msj)
                if(res.status == 202){
                    this.socio.estado=res.data.data.estado;
                    if(res.data.data.estado=='0'){
                        this.mensaje='Socio deshabilitado';
                        this.color_mensaje = 'danger'
                        // this.mensaje=res.data.msj;
                    }else{
                        this.mensaje='Socio habilitado';
                        this.color_mensaje = 'info'


                    }
                    
                }
                    
            })
            .catch(error => {
            console.log(error)
            // this.error = true;
            // this.error_msg = error.response.data.res;
            });
        },

    }
  
}
</script>

<style >
.selector-for-some-widget {
  box-sizing: content-box;
}
.enlaces{
    color: white; 
    text-decoration: none;

}
.enlaces:hover{
    color: rgb(236, 236, 236); 
    text-decoration: none;
}
.margen_izquierdo{
    margin-left: 10px;
}

.boton_quality{
  background: transparent !important;
  border: none !important;
}
</style>