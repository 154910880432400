<template>
  <div class="container cont">
    <img src="/imagenes/logo_t.png" alt="Descripción de la imagen" class="logo">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"> <router-link to="/dashboard">Volver</router-link></li>
      </ol>
    </nav>
    <h1>Agregar Socio </h1>

    <form v-on:submit.prevent = "agregarSocio">

        <div class="design">
            <div class="row">
                <div class="col">
                    <!-- Name input -->
                    <div class="form-outline" >
                    <input type="text" class="form-control" v-model="apellido" required>
                    <label class="form-label" for="form8Example1">Apellidos</label>
                    </div>
                </div>
                <div class="col">
                    <!-- Email input -->
                    <div class="form-outline">
                    <input type="text" class="form-control" v-model="nombres" required>
                    <label class="form-label">Nombres</label>
                    </div>
                </div>
                <div class="col">
                    <!-- Email input -->
                    <div class="form-outline">
                        <input type="text" class="form-control" v-model="dni" required>
                    <label class="form-label">DNI</label>
                    </div>
                </div>
                <div class="col">
                    <!-- Name input -->
                    <div class="form-outline">
                        <select v-model="genero" class="form-control" required>
                            <option value="Masculino">Masculino</option>
                            <option value="Femenino" selected>Femenino</option>
                        </select>
                    <label class="form-label">Género</label>
                    </div>
                </div>
            </div>

            <hr />

            <div class="row">
                <div class="col">
                    <!-- Name input -->
                    <div class="form-outline">
                        <input type="date" class="form-control" v-model="fecha_nacimiento">
                        <label class="form-label">Fecha de Nacimiento</label>
                    </div>
                </div>
                <div class="col">
                    <!-- Name input -->
                    <div class="form-outline">
                        <input type="email"  class="form-control" v-model="email">
                        <label class="form-label">Email</label>
                    </div>
                </div>
                <div class="col">
                    <!-- Name input -->
                    <div class="form-outline">
                        <input type="text" class="form-control" v-model="domicilio">
                        <label class="form-label">Domicilio</label>
                    </div>
                </div>
                <div class="col">
                    <!-- Email input -->
                    <div class="form-outline">
                        <input type="number" class="form-control" v-model="telefono" placeholder="2266000000">
                        <label class="form-label">Nº Celular</label> <br>
                         <span style="color:red">Sin el 15 - Ej: 2266123456</span>
                    </div>
                </div>
            </div>

            <hr />

            <div class="row">
                <!-- <div class="col">
                    <div class="form-outline">
                        <input type="file" class="form-control">
                        <label class="form-label">Foto</label>
                    </div>
                </div> -->
                <div class="col">
                    <!-- Name input -->
                    <div class="form-outline">
                        <input type="text"  class="form-control" v-model="altura" required>
                        <label class="form-label">Altura (m)</label> <br>
                        <span style="color:red">Ej: 1,70</span>

                    </div>
                </div>
                <div class="col">
                    <!-- Name input -->
                    <div class="form-outline">
                        <input type="text" class="form-control" v-model="peso" required>
                        <label class="form-label">Peso (kg)</label> <br>
                        <span style="color:red">Ej: 72.3 </span>

                    </div>
                </div>
                <div class="col">
                    <!-- Email input -->
                    <div class="form-outline">
                        <input type="text" class="form-control" v-model="tipo_sangre" required>
                        <label class="form-label">Tipo de Sangre</label> <br>
                        <span style="color:red">Ej: A+ </span>

                    </div>
                </div>

            </div>
            <hr />

            <div class="row">
                <div class="col">
                    <input type="submit" class="fadeIn fourth" value="Agregar">
                </div>
            </div>

        </div>
    </form>
  </div>
</template>

<script>
// import {Global} from '@/Global'

import axios from "axios";

const Swal = require('sweetalert2')

  export default {
    name:'SocioAgregarView',
    methods: {
        agregarSocio(){

            const config = {
                headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
            };

            const bodyParameters = {
                nombres : this.nombres,
                apellidos : this.apellido,
                genero : this.genero,
                dni : this.dni,
                domicilio : this.domicilio,
                telefono : this.telefono,
                fecha_nacimiento : this.fecha_nacimiento,
                altura : this.altura,
                peso : this.peso,
                tipo_sangre : this.tipo_sangre,
                foto : '/images/foto.jpg',
                email : this.email,
                password : this.password,
                estado : "1",
                roles : [
                    "2"
                ]
            };

            axios.post(
            'http://apiquality.webssdigital.com.ar/api/socios',
            bodyParameters,
            config
            ).then(
                function ()
                {
                    Swal.fire({
                        title: 'Socios',
                        text: 'Socio agregado correctamente',
                        icon: 'success',
                        confirmButtonText: 'Cool',

                    }).then(() => this.$router.push({ name: 'dashboard' }))


                }

            )
            .catch(error => {
            console.log(error)
            // this.error = true;
            // this.error_msg = error.response.data.res;
            });


        }
    },
    computed: {
    },
    data() {
      return {
        apellido: '',
        nombres: '',
        genero:'',
        dni:'',
        domicilio:'',
        telefono:'',
        fecha_nacimiento:'',
        altura:'',
        peso:'',
        tipo_sangre:'',
        foto:'',
        password:'123456'


      }
    }
  }
</script>

<style scoped>
.cont{
  background: #dfdfdf;
  padding: 7em;
  border-radius: 0%;
  border-style: outset;
}
.design{
    margin-top:5%;
}
input[type=button], input[type=submit], input[type=reset]  {
  background-color: #56baed;
  border: none;
  color: white;
  padding: 15px 80px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  text-transform: uppercase;
  font-size: 13px;
  -webkit-box-shadow: 0 10px 30px 0 rgba(95,186,233,0.4);
  box-shadow: 0 10px 30px 0 rgba(95,186,233,0.4);
  -webkit-border-radius: 5px 5px 5px 5px;
  border-radius: 5px 5px 5px 5px;
  margin: 5px 20px 40px 20px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
</style>