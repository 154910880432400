import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import DashboardView from '../views/DashboardView.vue'
import SocioView from '../views/SocioView.vue'
import RutinaEditarView from '../views/RutinaEditarView.vue'
import SocioAgregarView from '../views/SocioAgregarView.vue'
import SocioEditarView from '../views/SocioEditarView.vue'
import EjercicioAgregarView from '../views/EjercicioAgregarView.vue'
import EjercicioEditarView from '../views/EjercicioEditarView.vue'

import RutinaAgregarView from '../views/RutinaAgregarView.vue'
import VistaPreviaPage from '../views/VistaPreviaPage.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: DashboardView,
    meta: {requireAuth: true}
  },
  {
    path: '/socio/:id',
    name: 'socio',
    component: SocioView,
    meta: {requireAuth: true}
  },
  {
    path: '/vista-previa',
    name: 'VistaPrevia',
    component: VistaPreviaPage,
    props: true
  },

  {
    path: '/socio/editar/',
    name: 'socioEditar',
    component: SocioEditarView,
    props: true,
    meta: {requireAuth: true}
  },
  {
    path: '/socioAgregar',
    name: 'socioAgregar',
    component: SocioAgregarView,
    meta: {requireAuth: true}
  },
  {
    path: '/ejercicioAgregar',
    name: 'ejercicioAgregar',
    component: EjercicioAgregarView,
    meta: {requireAuth: true}
  },
  {
    path: '/ejercicioEditar',
    name: 'ejercicioEditar',
    component: EjercicioEditarView,
    meta: {requireAuth: true}
  },
  {
    path: '/socio/rutinaAgregar/:id',
    name: 'rutinaAgregar',
    component: RutinaAgregarView,
    meta: {requireAuth: true}
  },
  {
    path: '/socio/rutinaEditar/',
    name: 'rutinaEditar',
    component: RutinaEditarView,
    props: true,
    meta: {requireAuth: true}
  },

  {
    path: '/about',
    name: 'about',

    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  
  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


router.beforeEach((to, from, next) => {
  const rutaProtegida = to.matched.some(record => record.meta.requireAuth);

  // console.log(store.getters)(localStorage.getItem('token'))
    if(rutaProtegida && localStorage.getItem('token') === null){
        // ruta protegida es true
        // token es nulo true, por ende redirigimos al inicio
 
        next({name: 'home'})
    }
    else{
        // En caso contrario sigue...
        next()
    }

})

export default router
